import React from "react";
import logo from "./../assets/img/logo.png";
import { AppInfo } from "./appInfo";

const Brand = () => {
  const goToApp = () => {
    window.location.href = "https://digital.pass-card.app";
  };
  return (
    <div className="divide-y">
      <div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="w-screen space-y-8">
          <img className="mx-auto h-12 w-auto" src={logo} alt="Pass logo" />
          <h2 className="mt-6 text-center text-2xl font-bold tracking-tight text-gray-900">
            Pass
          </h2>
          <p className="mt-2 text-center text-sm text-gray-600">
            World's most versatile digital business card.
          </p>
          <div className="flex justify-center gap-4">
            <button
              className="text-sm font-bold text-center p-4 bg-emerald-600 text-white rounded-md"
              onClick={goToApp}
            >
              Get Pass now!
            </button>
          </div>
        </div>
      </div>
      <AppInfo />
      <footer className="fixed bottom-0 left-0 right-0 flex md:items-center md:justify-between mb-0">
        <div className="bg-white w-full p-4">
          <span className="text-sm text-gray-500 sm:text-center dark:text-gray-400 ">
            © {new Date().getFullYear()}{" "}
            <a href="https://pass.rw/" className="hover:underline">
              Pass
            </a>
            . All Rights Reserved.
          </span>
        </div>
      </footer>
    </div>
  );
};

export { Brand };
