import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";

const devConfig = {
  apiKey: "AIzaSyCaShUSrwYHHsngNBN3qZwD-0hTqpPV5pE",
  authDomain: "pass-dev-24a88.firebaseapp.com",
  projectId: "pass-dev-24a88",
  storageBucket: "pass-dev-24a88.appspot.com",
  messagingSenderId: "676808752965",
  appId: "1:676808752965:web:4caf4cda7acf5fcefb482c",
  measurementId: "G-4Z1LQ8GFMM",
};
const prodConfig = {
  apiKey: "AIzaSyCChVpOKWyEdALK3GEFobECSnovhd4331s",
  authDomain: "pass-8d311.firebaseapp.com",
  projectId: "pass-8d311",
  storageBucket: "pass-8d311.appspot.com",
  messagingSenderId: "647188068246",
  appId: "1:647188068246:web:0d03c1902a10e51a020fb7",
  measurementId: "G-Z5W0X0BB5S",
};

const config = process.env.NODE_ENV === "production" ? prodConfig : devConfig;
const app = initializeApp(config);
const appAnalytics = getAnalytics(app);
const appDb = getFirestore(app);
export { appAnalytics, appDb };
