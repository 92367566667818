import React, { useState } from "react";

const Image = (props) => {
  const [loaded, setLoaded] = useState(false);

  return (
    <>
      <img
        className={`${
          loaded ? "h-56 w-auto" : "h-0"
        } w-full m-auto bg-white rounded-md shadow-lg border-gray-200`}
        src={props.src}
        alt="My business card"
        onLoad={() => {
          setLoaded(true);
        }}
      />
      {!loaded && (
        <div
          role="status"
          class="w-full h-56 m-auto bg-white rounded-md shadow-lg border-gray-200 bg-gray-200 rounded-lg animate-pulse dark:bg-gray-700"
        ></div>
      )}
    </>
  );
};

export { Image };
