import React from "react";
import {
  PhoneIcon,
  EnvelopeIcon,
  GlobeAltIcon,
} from "@heroicons/react/24/outline";
import { SocialIcon } from "react-social-icons";

const Links = ({ email, phone, website, links }) => {
  const sendEmail = () => {
    window.location.href = `mailto:${email}`;
  };
  const callPhone = () => {
    window.location.href = `tel:${phone}`;
  };
  const openWebsite = (site) => {
    if (site.includes("http") || site.includes("https")) {
      window.open(site, "_blank");
    } else {
      window.open("http://" + site, "_blank");
    }
  };

  return (
    <div className="gap-4 p-4">
      {email && email !== "" && (
        <button
          className="flex items-center gap-2 py-2 w-full"
          onClick={sendEmail}
        >
          <div className="text-slate-700 flex items-center justify-center ">
            <EnvelopeIcon className="w-5 h-5" />
          </div>
          <div className=" text-left">
            <div className="text-md">{email}</div>
          </div>
        </button>
      )}
      {phone && phone !== "" && (
        <button
          className="flex align-center items-center gap-2 py-2 w-full"
          onClick={callPhone}
        >
          <div className="text-slate-700 flex items-center justify-center ">
            <PhoneIcon className="w-5 h-5" />
          </div>
          <div className="text-left">
            <div className="text-md">{phone}</div>
          </div>
        </button>
      )}
      {website && website !== "" && (
        <button
          className="flex gap-2 py-2 w-full items-center"
          onClick={() => openWebsite(website)}
        >
          <div className="text-slate-700 flex items-center justify-center ">
            <GlobeAltIcon className="w-5 h-5" />
          </div>
          <div className="text-left">
            <div className="text-md">{website}</div>
          </div>
        </button>
      )}

      <div className="flex flex-row gap-2 items-start justify-start pt-2 flex-wrap">
        {links && links.linkedin && links.linkedin !== "" && (
          <button
            className="flex  gap-2 py-2"
            onClick={() => openWebsite(links.linkedin)}
          >
            <SocialIcon url={links.linkedin} network="linkedin" />
          </button>
        )}

        {links && links.x && links.x !== "" && (
          <button
            className="flex  gap-2 py-2"
            onClick={() => openWebsite(links.x)}
          >
            <SocialIcon url={links.x} network="x" />
          </button>
        )}

        {links && links.github && links.github !== "" && (
          <button
            className="flex  gap-2 py-2"
            onClick={() => openWebsite(links.github)}
          >
            <SocialIcon url={links.github} network="github" />
          </button>
        )}

        {links && links.youtube && links.youtube !== "" && (
          <button
            className="flex  gap-2 py-2"
            onClick={() => openWebsite(links.youtube)}
          >
            <SocialIcon url={links.youtube} network="youtube" />
          </button>
        )}

        {links && links.instagram && links.instagram !== "" && (
          <button
            className="flex  gap-2 py-2"
            onClick={() => openWebsite(links.instagram)}
          >
            <SocialIcon url={links.instagram} network="instagram" />
          </button>
        )}

        {links && links.facebook && links.facebook !== "" && (
          <button
            className="flex  gap-2 py-2"
            onClick={() => openWebsite(links.facebook)}
          >
            <SocialIcon url={links.facebook} network="facebook" />
          </button>
        )}
      </div>
    </div>
  );
};

export { Links };
